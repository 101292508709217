import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../state/app';
import { RichText } from '../richTextOptions';
import { Image } from './Common/Image';
import Modal from 'react-bootstrap/Modal';
// import { Tooltip } from './Common/Tooltip';
import { useOutsideHandling } from '../Hooks/CustomHooks';

const SectionProviderComparisonV2 = (props) => {
     const {
          comparisonFooterDescription,
          comparisonTitle,
          // popularityAmongPetOwnersTooltip,
          // monthlyPremiumTooltip,
          // flexibleFullyCustomizableTooltip,
          isPreview
     } = props;

     let { otherProviders, nationwideProvider, exampleReimbursementOptions } = props;

     const changeArray = (array) => {
          let newArray = [];
          if (Array.isArray(array)) {
               array.map((value) => newArray.push(value.fields));
          }
          return newArray;
     };

     if (isPreview) {
          exampleReimbursementOptions = changeArray(exampleReimbursementOptions);
          nationwideProvider = nationwideProvider.fields;
          otherProviders = changeArray(otherProviders);
     }

     const [activeProvider, setActiveProvider] = useState(otherProviders?.[0]?.providerName);
     const [activeExampleReimbursementOption, setActiveExampleReimbursementOption] = useState(exampleReimbursementOptions?.[0].reimbursementName);
     const [openPopupSelectHealthyPaws, setOpenPopupSelectHealthyPaws] = useState(false);
     const [openPopupSelectExample, setOpenPopupSelectExample] = useState(false);
     const [openSelectProvider, setOpenSelectProvider] = useState(false);
     const [openSelectExampleReimbursementOption, setOpenSelectExampleReimbursementOption] = useState(false);

     const handleOnChangeSelectOtherProviders = (value) => {
          setActiveProvider(value);
          setOpenSelectProvider(false);
          setOpenPopupSelectHealthyPaws(false);
     };

     const handleOnChangeSelectExampleReimbursementOptions = (value) => {
          setActiveExampleReimbursementOption(value);
          setOpenPopupSelectExample(false);
          setOpenSelectExampleReimbursementOption(false);
     };

     const handleOpenSelectProvider = useCallback(() => {
          setTimeout(() => {
               setOpenSelectProvider(!openSelectProvider);
          }, 100);
     }, [openSelectProvider]);

     const handleOpenSelectExampleReimbursementOption = useCallback(() => {
          setTimeout(() => {
               setOpenSelectExampleReimbursementOption(!openSelectExampleReimbursementOption);
          }, 100);
     }, [openSelectExampleReimbursementOption]);

     const providerRef = useRef(null);
     const reimbursementRef = useRef(null);

     const handleClickOutsideSelectProvider = useCallback(() => {
          setOpenSelectProvider(false);
     }, []);

     const handleClickOutsideSelectExampleReimbursementOption = useCallback(() => {
          setOpenSelectExampleReimbursementOption(false);
     }, []);

     useOutsideHandling(providerRef, handleClickOutsideSelectProvider);
     useOutsideHandling(reimbursementRef, handleClickOutsideSelectExampleReimbursementOption);

     return (
          <section className="comparison-v2">
               <div className="container">
                    {comparisonTitle && <RichText data={comparisonTitle} />}
                    <table>
                         <thead>
                              <tr>
                                   <th>Plan and pricing options</th>
                                   <th>
                                        {nationwideProvider?.providerLogoMobile ? (
                                             <>
                                                  {nationwideProvider?.providerLogo ? (
                                                       <>
                                                            <Image image={nationwideProvider.providerLogo} cla="sl-desktop" />
                                                            <Image image={nationwideProvider.providerLogoMobile} cla="sl-mobile" />
                                                       </>
                                                  ) : (
                                                       nationwideProvider?.providerName
                                                  )}
                                             </>
                                        ) : nationwideProvider?.providerLogo ? (
                                             <Image image={nationwideProvider.providerLogo} />
                                        ) : (
                                             nationwideProvider?.providerName
                                        )}
                                   </th>
                                   <th>
                                        <span className="sl-desktop">
                                             <div className="select-wrapper" ref={providerRef}>
                                                  <div className={`select-button ${openSelectProvider ? 'open' : ''}`}>
                                                       <button onClick={() => handleOpenSelectProvider()}>{!openSelectProvider ? activeProvider : 'Select one'}</button>
                                                  </div>
                                                  {openSelectProvider && (
                                                       <div className="list-select-wrapper">
                                                            <div className="list-select">
                                                                 {Array.isArray(otherProviders) &&
                                                                      otherProviders.map((otherProvider) => (
                                                                           <div key={otherProvider.id} className="select-item">
                                                                                <button onClick={() => handleOnChangeSelectOtherProviders(otherProvider.providerName)}>
                                                                                     {otherProvider.providerName}
                                                                                </button>
                                                                           </div>
                                                                      ))}
                                                            </div>
                                                       </div>
                                                  )}
                                             </div>
                                        </span>
                                        <span className="sl-mobile">
                                             <label>Healthy Paws</label>
                                             <button onClick={() => setOpenPopupSelectHealthyPaws(true)}>change</button>
                                        </span>
                                   </th>
                              </tr>
                         </thead>
                         <tbody>
                              {/* <tr>
                                   <td>
                                        {flexibleFullyCustomizableTooltip ? (
                                             <Tooltip content={flexibleFullyCustomizableTooltip} action="click" labelClick="Flexible, fully customizable  medical coverage" />
                                        ) : (
                                             'Flexible, fully customizable  medical coverage'
                                        )}
                                        Accident & illness coverage<sup>1</sup>
                                   </td>
                                   <td>
                                        {nationwideProvider?.flexibleFullyCustomizableMedicalCoverage ? (
                                             <img loading="lazy" src="/images/icons/icon-check-comparison.svg" alt="icon check" />
                                        ) : (
                                             <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                        )}
                                   </td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return (
                                                       <span key={i}>
                                                            {provider?.flexibleFullyCustomizableMedicalCoverage ? (
                                                                 <img loading="lazy" src="/images/icons/icon-grey-check-comparison.svg" alt="icon check" />
                                                            ) : (
                                                                 <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                                            )}
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                              </tr> */}
                              {/* <tr>
                                   <td>Wellness coverage available</td>
                                   <td>
                                        {nationwideProvider?.multipleWellnessCoverageOptionsAvailable ? (
                                             <img loading="lazy" src="/images/icons/icon-check-comparison.svg" alt="icon check" />
                                        ) : (
                                             <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                        )}
                                   </td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return (
                                                       <span key={i}>
                                                            {provider?.multipleWellnessCoverageOptionsAvailable ? (
                                                                 <img loading="lazy" src="/images/icons/icon-grey-check-comparison.svg" alt="icon check" />
                                                            ) : (
                                                                 <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                                            )}
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                              </tr> */}
                              {/* <tr>
                                   <td>
                                        {monthlyPremiumTooltip ? <Tooltip content={monthlyPremiumTooltip} action="click" labelClick="Monthly premium" /> : 'Monthly premium'}
                                        Base monthly premium
                                   </td>
                                   <td>
                                        $
                                        {nationwideProvider?.monthlyPremium &&
                                             // Math.round(nationwideProvider.monthlyPremium).toLocaleString('en-us')
                                             nationwideProvider.monthlyPremium.toLocaleString('en-us')}
                                   </td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return (
                                                       <span key={i}>
                                                            {provider?.monthlyPremium ? '$' : ''}
                                                            {provider?.monthlyPremium &&
                                                                 // Math.round(provider.monthlyPremium).toLocaleString('en-us')
                                                                 provider.monthlyPremium.toLocaleString('en-us')}
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                              </tr> */}

                              <tr>
                                   <td>Base monthly premium</td>
                                   <td>{nationwideProvider?.baseMonthlyPremium && nationwideProvider.baseMonthlyPremium}</td>

                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return <span key={i}>{provider?.baseMonthlyPremium && provider.baseMonthlyPremium}</span>;
                                             }
                                        })}
                                   </td>
                              </tr>

                              <tr>
                                   <td>Wellness rider premium</td>
                                   <td>{nationwideProvider?.wellnessRiderPremium && nationwideProvider.wellnessRiderPremium}</td>

                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return <span key={i}>{provider?.wellnessRiderPremium && provider.wellnessRiderPremium}</span>;
                                             }
                                        })}
                                   </td>
                              </tr>

                              <tr>
                                   <td>
                                        Monthly premium<sup>2</sup>
                                   </td>
                                   <td>{nationwideProvider?.monthlyPremium && <RichText data={nationwideProvider.monthlyPremium}></RichText>}</td>

                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return <span key={i}>{provider?.monthlyPremium && <RichText data={provider.monthlyPremium}></RichText>}</span>;
                                             }
                                        })}
                                   </td>
                              </tr>

                              <tr>
                                   <td>
                                        <label>Example reimbursement</label>
                                        <span className="sl-desktop">
                                             <div className="select-wrapper small">
                                                  <div className={`select-button ${openSelectExampleReimbursementOption ? 'open' : ''}`}>
                                                       <button ref={reimbursementRef} onClick={() => handleOpenSelectExampleReimbursementOption()}>
                                                            {!openSelectExampleReimbursementOption ? activeExampleReimbursementOption : 'Select one'}
                                                       </button>
                                                  </div>
                                                  {openSelectExampleReimbursementOption && (
                                                       <div className="list-select-wrapper">
                                                            <div className="list-select">
                                                                 {Array.isArray(exampleReimbursementOptions) &&
                                                                      exampleReimbursementOptions.map((exampleReimbursementOption) => (
                                                                           <div key={exampleReimbursementOption.id} className="select-item">
                                                                                <button
                                                                                     onClick={() =>
                                                                                          handleOnChangeSelectExampleReimbursementOptions(
                                                                                               exampleReimbursementOption.reimbursementName
                                                                                          )
                                                                                     }
                                                                                >
                                                                                     {exampleReimbursementOption.reimbursementName}
                                                                                </button>
                                                                           </div>
                                                                      ))}
                                                            </div>
                                                       </div>
                                                  )}
                                             </div>
                                        </span>
                                        <span className="sl-mobile">
                                             <button onClick={() => setOpenPopupSelectExample(true)}>select</button>
                                        </span>
                                   </td>
                                   <td>
                                        {nationwideProvider?.claimsExamples?.map((exReimbursement, i) => {
                                             let claimReimbursementName;

                                             if (isPreview) {
                                                  exReimbursement = exReimbursement.fields;
                                                  claimReimbursementName = exReimbursement.reimbursementType.fields.reimbursementName;
                                             } else {
                                                  claimReimbursementName = exReimbursement.reimbursementType.reimbursementName;
                                             }

                                             if (activeExampleReimbursementOption === claimReimbursementName) {
                                                  const percentage = Math.round((exReimbursement.reimbursement / exReimbursement.vetBill) * 100);

                                                  return (
                                                       <span key={i}>
                                                            <span>
                                                                 Vet bill: $
                                                                 {exReimbursement.vetBill.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </span>

                                                            <span className="sl-desktop">
                                                                 Reimbursement: $
                                                                 {exReimbursement.reimbursement.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </span>
                                                            <span className="sl-mobile">
                                                                 Reimb: $
                                                                 {exReimbursement.reimbursement.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </span>
                                                            <span>Percentage of reimbursement: {percentage}%</span>
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return (
                                                       <span key={i}>
                                                            {provider?.claimsExamples?.map((exReimbursement, i) => {
                                                                 let claimReimbursementName;

                                                                 if (isPreview) {
                                                                      exReimbursement = exReimbursement.fields;
                                                                      claimReimbursementName = exReimbursement.reimbursementType.fields.reimbursementName;
                                                                 } else {
                                                                      claimReimbursementName = exReimbursement.reimbursementType.reimbursementName;
                                                                 }

                                                                 if (activeExampleReimbursementOption === claimReimbursementName) {
                                                                      const percentage = Math.round((exReimbursement.reimbursement / exReimbursement.vetBill) * 100);

                                                                      return (
                                                                           <span key={i}>
                                                                                <span>
                                                                                     Vet bill: $
                                                                                     {exReimbursement.vetBill.toLocaleString('en-us', {
                                                                                          minimumFractionDigits: 2,
                                                                                          maximumFractionDigits: 2
                                                                                     })}
                                                                                </span>

                                                                                <span className="sl-desktop">
                                                                                     Reimbursement: $
                                                                                     {exReimbursement.reimbursement.toLocaleString('en-us', {
                                                                                          minimumFractionDigits: 2,
                                                                                          maximumFractionDigits: 2
                                                                                     })}
                                                                                </span>
                                                                                <span className="sl-mobile">
                                                                                     Reimb: $
                                                                                     {exReimbursement.reimbursement.toLocaleString('en-us', {
                                                                                          minimumFractionDigits: 2,
                                                                                          maximumFractionDigits: 2
                                                                                     })}
                                                                                </span>
                                                                                <span>Percentage of reimbursement: {percentage}%</span>
                                                                           </span>
                                                                      );
                                                                 }
                                                            })}
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                              </tr>
                              {/* <tr>
                                   <td>Pet prescription discounts</td>
                                   <td>
                                        {nationwideProvider?.petPrescriptionDiscounts ? (
                                             <img loading="lazy" src="/images/icons/icon-check-comparison.svg" alt="icon check" />
                                        ) : (
                                             <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                        )}
                                   </td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return (
                                                       <span key={i}>
                                                            {provider?.petPrescriptionDiscounts === 'Yes' ? (
                                                                 <img loading="lazy" src="/images/icons/icon-grey-check-comparison.svg" alt="icon check" />
                                                            ) : (
                                                                 <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                                            )}
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                              </tr> */}
                              <tr>
                                   <td>Telehealth - 24/7 access to veterinary experts</td>
                                   <td>
                                        {nationwideProvider?.accessToVeterinaryProfessionals ? (
                                             <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                        ) : (
                                             <img loading="lazy" src="/images/icons/icon-check-comparison.svg" alt="icon check" />
                                        )}
                                   </td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return (
                                                       <span key={i}>
                                                            {provider?.telehealth247Access === 'Yes' ? (
                                                                 <img loading="lazy" src="/images/icons/icon-grey-check-comparison.svg" alt="icon check" />
                                                            ) : (
                                                                 <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                                            )}
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                              </tr>
                              <tr>
                                   <td>
                                        {/* {
                                    popularityAmongPetOwnersTooltip ? <Tooltip content={popularityAmongPetOwnersTooltip} action="click" labelClick="Popularity among pet owners" /> : (
                                        "Popularity among pet owners"
                                    )
                                } */}
                                        Popularity among pet owners<sup>1</sup>
                                   </td>
                                   <td>{nationwideProvider?.popularityAmongPetOwners && nationwideProvider.popularityAmongPetOwners}</td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return <span key={i}>{provider?.popularityAmongPetOwners && provider.popularityAmongPetOwners}</span>;
                                             }
                                        })}
                                   </td>
                              </tr>
                              <tr>
                                   <td>Best value</td>
                                   <td>
                                        {nationwideProvider?.bestValue === 'Yes' ? (
                                             <img loading="lazy" src="/images/icons/icon-check-comparison.svg" alt="icon check" />
                                        ) : nationwideProvider?.bestValue === 'No' ? (
                                             <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                        ) : (
                                             <RichText data={nationwideProvider.bestValueRichText}></RichText>
                                        )}
                                   </td>
                                   <td>
                                        {otherProviders.map((provider, i) => {
                                             if (provider.providerName === activeProvider) {
                                                  return (
                                                       <span key={i}>
                                                            {provider?.bestValue === 'Yes' ? (
                                                                 <img loading="lazy" src="/images/icons/icon-grey-check-comparison.svg" alt="icon check" />
                                                            ) : provider?.bestValue === 'No' ? (
                                                                 <img loading="lazy" src="/images/icons/icon-uncheck-comparison.svg" alt="icon uncheck" />
                                                            ) : (
                                                                 <RichText data={provider.bestValueRichText}></RichText>
                                                            )}
                                                       </span>
                                                  );
                                             }
                                        })}
                                   </td>
                              </tr>
                         </tbody>
                    </table>
                    {comparisonFooterDescription && (
                         <div className="footer-description">
                              <RichText data={comparisonFooterDescription} />
                         </div>
                    )}
                    <Modal className={`popup-select`} show={openPopupSelectHealthyPaws} onHide={() => setOpenPopupSelectHealthyPaws(false)}>
                         <Modal.Body>
                              <div className="popup-select comparison-v2">
                                   <div className="title">
                                        <p>Choose your option:</p>
                                   </div>
                                   <div className="select-wrapper" ref={providerRef}>
                                        <div className={`select-button ${openSelectProvider ? 'open' : ''}`}>
                                             <button onClick={() => handleOpenSelectProvider()}>{!openSelectProvider ? activeProvider : 'Select one'}</button>
                                        </div>
                                        {openSelectProvider && (
                                             <div className="list-select-wrapper">
                                                  <div className="list-select">
                                                       {Array.isArray(otherProviders) &&
                                                            otherProviders.map((otherProvider) => (
                                                                 <div key={otherProvider.id} className="select-item">
                                                                      <button onClick={() => handleOnChangeSelectOtherProviders(otherProvider.providerName)}>
                                                                           {otherProvider.providerName}
                                                                      </button>
                                                                 </div>
                                                            ))}
                                                  </div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </Modal.Body>
                    </Modal>
                    <Modal className={`popup-select`} show={openPopupSelectExample} onHide={() => setOpenPopupSelectExample(false)}>
                         <Modal.Body>
                              <div className="popup-select comparison-v2">
                                   <div className="title">
                                        <p>Choose your option:</p>
                                   </div>
                                   <div className="select-wrapper small">
                                        <div className={`select-button ${openSelectExampleReimbursementOption ? 'open' : ''}`}>
                                             <button ref={reimbursementRef} onClick={() => handleOpenSelectExampleReimbursementOption()}>
                                                  {!openSelectExampleReimbursementOption ? activeExampleReimbursementOption : 'Select one'}
                                             </button>
                                        </div>
                                        {openSelectExampleReimbursementOption && (
                                             <div className="list-select-wrapper">
                                                  <div className="list-select">
                                                       {Array.isArray(exampleReimbursementOptions) &&
                                                            exampleReimbursementOptions.map((exampleReimbursementOption) => (
                                                                 <div key={exampleReimbursementOption.id} className="select-item">
                                                                      <button
                                                                           onClick={() =>
                                                                                handleOnChangeSelectExampleReimbursementOptions(exampleReimbursementOption.reimbursementName)
                                                                           }
                                                                      >
                                                                           {exampleReimbursementOption.reimbursementName}
                                                                      </button>
                                                                 </div>
                                                            ))}
                                                  </div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </Modal.Body>
                    </Modal>
               </div>
          </section>
     );
};

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

SectionProviderComparisonV2.propTypes = {
     isPreview: PropTypes.bool
};
const ContentfulSectionProviderComparisonV2 = connect(mapStateToProps)(SectionProviderComparisonV2);

export default ContentfulSectionProviderComparisonV2;
